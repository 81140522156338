@import 'styles/colors';

.login-page-container {
  display: flex;
  height: 100vh;
  background-color: $beige-50;

  .login-image-section {
    width: 50%;

    .logo-container {
      margin: 50px 0 0 64px;
    }

    .login-image-container {
      height: calc(100% - 44px - 50px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .login-image {
      width: 70%;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .login-form-section {
    width: 50%;
    background-color: $white;

    .login-form-section-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .login-title {
        height: 32;
      }

      .login-form-container {
        width: 334px;

        form {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }

        .actions {
          button {
            width: 100%;
          }
        }

        .forgot-password-text {
          cursor: pointer;
          width: fit-content;
        }
      }

      .account-creation-container {
        display: flex;
        margin-top: 40px;
        justify-content: center;

        .account-creation-section {
          width: 301px;
          text-align: center;
          margin-top: 40px;

          .account-creation-title-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;

            .ask-for-help-btn-wrapper {
              color: $blue-600;
              margin-top: 12px;
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
