@import '../../../styles/colors';
@import '../../../styles/text';
@import '../../../styles/shadow';
@import '../../../styles/select';

.item-count-pagination {
  display: flex;
  align-items: center;

  span {
    @extend .base-regular;

    margin-right: 8px;
    color: $gray-900;
  }

  .pagination-select {
    @extend .select;

    width: 75px !important;
  }
}

.page-nav-pagination {
  display: flex;
  align-items: center;

  button {
    width: 38px !important;
    height: 38px !important;

    margin-right: 12px;

    i {
      font-size: 20px;
    }
  }

  span {
    @extend .base-regular;

    margin-right: 12px;
    color: $gray-900;
  }
}
