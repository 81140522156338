@import 'styles/_shadow.scss';

.cookie-consent-banner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  gap: 24px;
  background-color: #ffffff;
  padding: 24px;
  z-index: 10000;
  border-radius: 6px;
  @extend .shadow-lg;

  .cookie-consent-action {
    display: flex;
    gap: 8px;
  }

  .cc-title {
    margin-top: 0;
    margin-bottom: 8px;
    padding: 0;
  }
}
