@import 'styles/colors';
@import 'styles/text';
@import 'styles/shadow';

.user-profile-dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-profile-header {
  @extend .base-regular;
  color: $brand-midnight;
  font-weight: 400;
  line-height: 0px;
}

.user-profile-sub-header {
  @extend .small-medium;
  color: $gray-500;
  font-weight: 400;
  line-height: 0px;
  padding-bottom: 15px;
}

.avatar-content-drp {
  padding-top: 15px;
  padding-bottom: 15px;
}

.user-profile-footer {
  display: flex;
  padding: 8px 0px;
  cursor: pointer;

  &:hover {
    background-color: $gray-50;
    border-radius: 4px;
  }
}

.footer-logout {
  margin-top: 0;
  line-height: normal;
  margin-bottom: 0;
  margin-left: 8px;
}

.dropdown-wrapper {
  @extend .shadow-lg;
  position: absolute;
  margin-top: 5px;
  z-index: 1;
  background-color: $white;
  border: 1px solid $gray-100;
  border-radius: 6px;
  padding: 8px 16px;
  width: 224px;

  &--child {
    @extend .dropdown-wrapper;
    width: auto;
  }

  &--sort {
    @extend .dropdown-wrapper;
    width: 240px;
    right: 90px;
  }

  &--configuration {
    @extend .dropdown-wrapper;
    width: 240px;
    right: 43px;
  }

  &--userProfile {
    @extend .dropdown-wrapper;
    width: 238px;
    right: 0px;
    top: 30px;
    // height: 256px;

    button.btn-base.btn-white {
      padding: 8px 17px;
    }
  }
  &--seeMoreNav {
    @extend .dropdown-wrapper;
    width: 250px;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 42px;
    right: 0;
  }
}

.horizontal-line {
  border-top: 1px solid $gray-200;
  padding-left: 0px;
  position: relative;
  margin-top: -4px;
}
