.select {
  @extend .base-regular;
  @extend .shadow-sm;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  align-items: center;
  margin: 4px 0;
  padding: 8px 12px;
  box-sizing: border-box;
  position: static;
  color: $gray-900;

  background-color: $white;
  border: 1px solid $gray-300;
  border-radius: 6px;

  appearance: none;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%236B7280%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%236B7280%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-position: calc(100% - 20px) calc(0.7em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  background-size: 13px;
  background-repeat: no-repeat;

  &:hover {
    ::placeholder {
      color: $gray-600;
    }
  }

  &:focus {
    @extend .shadow-sm;
    outline: $blue-500 solid 2px;
  }
}

.dropdown-select.loading-dropdown-select {
  background-image: none;
  justify-content: space-between;
  pointer-events: none;
}
