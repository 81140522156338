@import '../../../styles/colors';
@import '../../../styles/shadow';
@import '../../../styles/text';

.snackbar-container {
  box-shadow: none !important;
  padding: 0 !important;
  overflow: visible !important;
}

.snackbar {
  @extend .shadow-lg;

  background-color: $white;
  position: fixed;
  z-index: 1101;
  bottom: 1em;
  left: 1em;
  display: flex;
  width: 446px;
  height: 64px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 13px 25px;
  border: 1px solid $gray-100;
  border-radius: 11px;

  .text-message {
    width: 128px;
    margin-right: 8px;

    span {
      @extend .small-medium;
    }
  }

  .buttons {
    display: flex;

    button {
      margin-left: 8px;
    }
  }
}

.org-tree-update-confirmation {
  .snackbar > .buttons {
    justify-content: space-between;
    width: 100%;

    button {
      margin-bottom: 0;
      margin-left: 0;
    }
  }
}
