@import '../../../styles/colors';
@import '../../../styles/text';

.avatar-container {
  display: flex;
  align-items: center;
  align-content: center;

  .avatar-default {
    @extend .base-medium;

    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $surface-default;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $gray-900;
  }

  .avatar-img-default {
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 3.85px;

    svg {
      fill: $icon-subdued;
    }
  }

  .decorative-one {
    background-color: $decorative-surface-one;
    color: $decorative-text-one;

    svg {
      fill: $decorative-icon-one;
    }
  }

  .decorative-two {
    background-color: $decorative-surface-two;
    color: $decorative-text-two;

    svg {
      fill: $decorative-icon-two;
    }
  }

  .decorative-three {
    background-color: $decorative-surface-three;
    color: $decorative-text-three;

    svg {
      fill: $decorative-icon-three;
    }
  }

  .decorative-four {
    background-color: $decorative-surface-four;
    color: $decorative-text-four;

    svg {
      fill: $decorative-icon-four;
    }
  }

  .decorative-five {
    background-color: $decorative-surface-five;
    color: $decorative-text-five;

    svg {
      fill: $decorative-icon-five;
    }
  }

  .avatar-img-large {
    @extend .avatar-img-default;

    padding-bottom: 5.77px;
  }

  .color-one {
    background-color: $avatar-pale-rust;
    color: $beige-200;

    svg {
      fill: $beige-200;
    }
  }

  .color-two {
    background-color: $avatar-mint-dark;
    color: $green-200;

    svg {
      fill: $green-200;
    }
  }

  .color-three {
    background-color: $avatar-violet-red;
    color: $gray-300;

    svg {
      fill: $gray-200;
    }
  }

  .color-four {
    background-color: $avatar-navy-vlue;
    color: $blue-200;

    svg {
      fill: $blue-300;
    }
  }

  .color-five {
    background-color: $avatar-rose;
    color: $red-200;

    svg {
      fill: $red-300;
    }
  }

  .avatar-img-small {
    @extend .avatar-img-default;

    padding-bottom: 3.08px;
  }

  .avatar-large {
    @extend .avatar-default;

    width: 60px;
    height: 60px;
    font-size: 26px;
  }

  .avatar-small {
    @extend .avatar-default;

    width: 32px;
    height: 32px;
    font-size: 14px;
  }

  .labels {
    display: grid;

    margin-left: 11px;

    .title-label {
      color: $gray-900;
      @extend .base-medium;
    }

    .secondary-label {
      @extend .base-regular;
      color: $gray-500;
    }
  }
}
