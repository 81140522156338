@import 'styles/colors';
@import 'styles/text';
@import 'styles/shadow';

.nav-menu-item-selected {
  border-radius: 4px;
  color: $blue-700 !important;

  i {
    color: $blue-700 !important;
  }
}

.nav-menu-item {
  @extend .base-medium;

  display: flex;
  margin: auto 10px;
  padding: 5px;
  text-decoration: none;
  color: $brand-midnight;

  i {
    margin-right: 5px;
    font-size: 20px;
  }
}

.menu-see-more {
  position: relative;
}

.nav-button-item {
  background-color: transparent;
  display: flex;
  gap: 5px;
  align-items: center;
  margin: auto 10px;
  padding: 5px;
  color: $brand-midnight;
  border: none;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter;
  cursor: pointer;

  i {
    @extend .base-medium;

    color: $gray-800;
    font-size: 20px;
  }
}

.menu-item {
  display: flex;
  align-items: center;
  position: relative;

  .nav-submenu {
    position: absolute;
    background-color: #ffffff;
    top: 28px;
    right: 0;
    border-radius: 4px;
    min-width: 180px;
    @extend .shadow-lg;
    z-index: 1000;
    padding: 4px 0;

    a {
      display: block;
      @extend .base-medium;
      text-decoration: none;
      color: $brand-midnight;
      padding: 8px 12px;

      &:hover {
        background-color: $gray-50;
      }
    }
  }

  .nav-submenu-wrapper {
    position: relative;
    bottom: -2px;
    left: -8px;
    cursor: pointer;

    i {
      display: block;
      transition: transform 0.3s linear;
    }

    .i-rotated {
      transform: rotate(180deg);
    }
  }
}
