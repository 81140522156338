@import 'styles/colors';
@import 'styles/text';
@import 'styles/shadow';

.dropdown-wrapper {
  @extend .shadow-lg;
  position: absolute;
  margin-top: 5px;
  z-index: 1;
  background-color: $white;
  border: 1px solid $gray-100;
  border-radius: 6px;
  padding: 8px 16px;
  min-width: 234px;

  &--child {
    @extend .dropdown-wrapper;
    width: auto;
  }

  &--sort {
    @extend .dropdown-wrapper;
    width: 240px;
    right: 90px;
  }

  &--configuration {
    @extend .dropdown-wrapper;
    width: 240px;
    right: 43px;
  }

  &--orgunitSelection {
    @extend .dropdown-wrapper;
    right: 60px;
    top: 30px;
    max-height: 260px;
    overflow-y: auto;

    button.btn-base.btn-white {
      padding: 8px 17px;
    }

    .orgunit-list-wrapper {
      .orgunit-list-spinner-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
      }
      .orgunit-list-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
  &--seeMoreNav {
    @extend .dropdown-wrapper;
    width: 250px;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 42px;
    right: 0;
  }
}

.permission-item-container {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;

  .permission-item-icon {
    font-size: 20px;
    cursor: pointer;
    color: $gray-500;
  }

  .selected {
    @extend .permission-item-icon;
    color: $yellow-400 !important;
  }
}
