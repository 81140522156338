@import '../../../styles/colors';
@import '../../../styles/text';

.Toastify__toast-container {
  width: 396px !important;
  position: relative;
  border-radius: 4px;
}

.Toastify__toast {
  border-radius: 4px;
}

.alert-main-container {
  border: solid 1px $blue-300 !important;
  background-color: $blue-50 !important;

  .Toastify__toast-body {
    align-items: flex-start !important;
  }

  .Toastify__close-button {
    opacity: 1 !important;
    margin: 5px !important;

    svg {
      color: $blue-500 !important;

      &:hover {
        height: 18px;
        width: 16px;
      }
    }
  }
}

.alert-main-container-info {
  @extend .alert-main-container;

  border-color: $blue-300 !important;
  background-color: $blue-50 !important;

  .Toastify__close-button {
    svg {
      color: $blue-500 !important;
    }
  }
}

.alert-main-container-success {
  @extend .alert-main-container;

  border-color: $green-300 !important;
  background-color: $green-50 !important;

  .Toastify__close-button {
    svg {
      color: $green-800 !important;
    }
  }
}

.alert-main-container-warning {
  @extend .alert-main-container;

  border-color: $yellow-300 !important;
  background-color: $yellow-50 !important;

  .Toastify__close-button {
    svg {
      color: $yellow-500 !important;
    }
  }
}

.alert-main-container-error {
  @extend .alert-main-container;

  border-color: $red-300 !important;
  background-color: $red-50 !important;

  .Toastify__close-button {
    svg {
      color: $red-500 !important;
    }
  }
}

.alert-container {
  display: flex;
  align-items: flex-start;
  padding: 16px;
}

.alert-icon-container {
  margin: 0 10px 0 0;
  display: inherit;
  padding-top: 4px;

  i {
    font-size: 20px;
  }
}

.alert-icon-info {
  color: $blue-600;
}

.alert-icon-success {
  color: $green-800;
}

.alert-icon-warning {
  color: $yellow-600;
}

.alert-icon-error {
  color: $red-500;
}

.alert-title {
  @extend .small-medium;
}

.alert-title-info {
  @extend .alert-title;

  color: $blue-800;
}

.toast-close-info {
  color: $blue-800;
}

.alert-title-success {
  @extend .alert-title;

  color: $green-800;
}

.toast-close-success {
  color: $green-800;
}

.alert-title-warning {
  @extend .alert-title;

  color: $yellow-800;
}

.toast-close-warning {
  color: $yellow-800;
}

.alert-title-error {
  @extend .alert-title;

  color: $red-800;
}

.toast-close-error {
  color: $red-800;
}

.alert-description {
  @extend .small-regular;

  color: $blue-700;
  margin: 4px 0px;
}

.alert-description-info {
  @extend .alert-description;

  color: $blue-700;
}

.alert-description-success {
  @extend .alert-description;

  color: $green-700;
}

.alert-description-warning {
  @extend .alert-description;

  color: $yellow-700;
}

.alert-description-error {
  @extend .alert-description;

  color: $red-700;
}

.toast-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 19px;
  cursor: pointer;
}
