@import '../../../styles/colors';
@import '../../../styles/shadow';
@import '../../../styles/text';

.date-input {
  @extend .base-regular;
  @extend .shadow-sm;

  min-width: 208px;
  max-width: fit-content;
  height: 40px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  box-sizing: border-box;
  color: $brand-midnight;
  border: 1px solid $gray-300;
  border-radius: 6px;
  padding: 8px 12px 8px 12px;

  .ri-calendar-line {
    font-size: 18px;
  }
}

.react-datepicker-popper {
  padding-top: 0 !important;

  .react-datepicker {
    @extend .small-regular;
    @extend .shadow-xl;

    display: flex;
    box-sizing: border-box;
    background-color: $white !important;
    border: unset !important;
    border-radius: 4px;

    .react-datepicker__triangle {
      display: none !important;
    }

    .react-datepicker__month-container {
      float: unset;
    }

    .react-datepicker__header {
      background-color: $white !important;
      border-bottom: unset !important;
    }

    .datepicker-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px 12px 20px;

      .month-button-prev,
      .month-button-next {
        font-size: 24px;
        cursor: pointer;
      }

      .month-year {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
          margin: 0 2px;
          cursor: pointer;
        }
      }
    }

    .react-datepicker__day-names {
      display: flex;
      justify-content: space-between;
      margin: 0 20px;
    }

    .react-datepicker__day {
      &:hover {
        color: $midnight;
        background-color: $gray-200;
        z-index: 1;
      }

      &:focus {
        background-color: $blue-600;
        box-sizing: border-box;
        box-shadow: 0px 0px 0px 2px #f2f2f2, 0px 0px 0px 4px $blue-600;
        border-radius: 4px;
        z-index: 1;
      }
    }

    .react-datepicker__day--selected {
      background-color: $blue-600;
      font-weight: inherit;
      z-index: 1;

      &:hover {
        color: $white;
        background-color: $blue-600;
      }
    }

    .react-datepicker__day--keyboard-selected {
      @extend .react-datepicker__day--selected;
    }

    .react-datepicker__day--disabled {
      color: $gray-400;
      cursor: not-allowed;

      &:hover {
        color: $gray-400;
        background-color: $white;
      }
    }

    .react-datepicker__month {
      display: flex;
      flex-direction: column;
      margin: 0 20px 20px 20px;

      .react-datepicker__week {
        display: flex;
        justify-content: space-around;
        padding: 1px;

        .react-datepicker__day {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px !important;
          height: 36px !important;
          margin: 1px 0 !important;
          text-align: center;
        }

        .react-datepicker__day--in-range {
          color: $midnight;
          background-color: $blue-200;
          border-radius: unset;
        }

        .react-datepicker__day--range-start {
          color: $white;
          background-color: $blue-600 !important;
          box-sizing: border-box !important;
          box-shadow: 0px 0px 0px 2px #f2f2f2, 0px 0px 0px 4px $blue-600 !important;
          border-radius: 20px 4px 4px 20px !important;
          z-index: 1 !important;
        }

        .react-datepicker__day--range-end {
          color: $white;
          background-color: $blue-600 !important;
          box-sizing: border-box !important;
          box-shadow: 0px 0px 0px 2px #f2f2f2, 0px 0px 0px 4px $blue-600 !important;
          border-radius: 4px 20px 20px 4px !important;
          z-index: 1 !important;
        }

        .react-datepicker__day--in-selecting-range {
          @extend .react-datepicker__day--in-range;
        }

        .react-datepicker__day--selecting-range-start {
          @extend .react-datepicker__day--range-start;
        }

        .react-datepicker__day--selecting-range-end {
          @extend .react-datepicker__day--range-end;
        }

        .react-datepicker__day--outside-month {
          background-color: $white !important;
          border: unset !important;
          box-shadow: unset !important;
          cursor: unset !important;
          color: $gray-300;
        }
      }

      .react-datepicker__month-wrapper {
        @extend .small-regular;

        font-weight: 500;
        display: flex;
        justify-content: space-around;
        margin: 5px 0;

        .react-datepicker__month-text {
          width: 84px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
        }

        .react-datepicker__month--selected,
        .react-datepicker__month-text--keyboard-selected {
          background-color: $blue-600;
          font-weight: inherit;
        }
      }
    }
    .react-datepicker__year {
      display: flex;
      flex-direction: column;
      margin: unset;
      margin: -15px 0 15px 0;

      .react-datepicker__year-wrapper {
        @extend .small-regular;

        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-evenly;
        max-width: unset;
        font-weight: 500;

        .react-datepicker__year-text {
          width: 84px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          margin: 15px 0 0 0;
        }

        .react-datepicker__year-text--selected {
          background-color: $blue-600;
          font-weight: inherit;
        }

        .react-datepicker__year-text--keyboard-selected {
          @extend .react-datepicker__year-text--selected;
        }
      }
    }
  }
}
