@import '../../../styles/colors';

.dimmer {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background-color: $black;
  opacity: 0.2;
}

.dimmer-white {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background-color: $white;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}
