@import 'styles/colors';

.help-and-support-modal-large {
  background-color: $gray-900;
  color: $gray-100;
  border-radius: 8px;
  overflow: hidden;

  .modal-header {
    background-color: $gray-900;
    height: 60px;
    box-shadow: none;

    h5 {
      font-family: 'Gimbal Grotesque';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .modal-body {
    background-color: $gray-900;
    padding-top: 0;

    .text-input-container {
      padding: 6px 0px 16px;

      .text-input-icon {
        color: $gray-300;
      }

      .help-support-search {
        &::placeholder {
          color: $gray-300;
        }
      }
    }

    .custom-divider {
      height: 0.5px;
      display: block;
      background-color: $gray-700;
      width: calc(100% + 48px);
      margin-left: -24px;
      margin-right: -24px;
    }

    .help-support-content-title {
      padding: 0;
      margin-top: 24px;
      margin-bottom: 0;
    }

    .help-support-content-color {
      color: $gray-100;
    }

    .help-support-content-wrapper {
      margin-top: 24px;

      .suggestion-item-container {
        display: flex;
        gap: 15px;
        align-items: flex-start;

        .suggestion-icon {
          position: relative;
          top: 4px;
        }

        .suggestion-paragraph {
          color: $gray-300;
        }

        .btn-icon-link {
          flex-direction: row-reverse;
          gap: 6px;
          padding-left: 0;
          color: $blue-400;

          i {
            font-size: 16px;
            position: relative;
            top: 1px;
          }

          .text {
            margin-left: 0;
            text-decoration: underline;
          }

          &:hover {
            color: $blue-500;
          }
        }

        &:not(:first-child) {
          margin-top: 24px;
        }
      }
    }
  }

  .modal-footer {
    background-color: $gray-800;

    .btn-icon-link {
      flex-direction: row-reverse;
      gap: 8px;
      color: $gray-100;
      padding: 5px 4px;
      font-size: 16px;

      .text {
        margin-left: 0;
      }

      &:hover {
        color: #ffffff;
        background-color: $gray-700;
      }
    }
  }
}
