@import '../../../styles/colors';

.progress-bar-container {
  background-color: $gray-200;
  z-index: 1102;

  .progress-bar-default {
    background-color: $green-600;
    height: 8px;
  }

  .progress-bar-small {
    @extend .progress-bar-default;

    height: 4px;
  }

  .progress-bar-large {
    @extend .progress-bar-default;

    height: 12px;
  }

  .grey-bar {
    background-color: $gray-600;
  }

  .red-bar {
    background-color: $red-600;
  }

  .yellow-bar {
    background-color: $yellow-600;
  }

  .green-bar {
    background-color: $green-600;
  }

  .blue-bar {
    background-color: $blue-600;
  }
}

.progress-spinner-default {
  z-index: 1102;
  border: 4px solid transparent;
  border-radius: 50%;
  border-top: 4px solid $green-600;
  border-right: 4px solid $green-600;
  border-bottom: 4px solid $green-600;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.progress-spinner-large {
  @extend .progress-spinner-default;

  width: 55px;
  height: 55px;
}
.progress-spinner-small {
  @extend .progress-spinner-default;
  border: 2px solid transparent;
  border-top: 2px solid $green-600;
  border-right: 2px solid $green-600;
  border-bottom: 2px solid $green-600;

  width: 12px;
  height: 12px;
}

.progress-spinner-color-gray {
  border-top-color: $gray-500;
  border-right-color: $gray-500;
  border-bottom-color: $gray-500;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
