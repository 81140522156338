@import '../../../styles/colors';
@import '../../../styles/text';

.radio-container {
  display: block;
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .description {
    @extend .small-regular;
    color: $gray-500;
    margin: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 2px;
    width: 16px;
    height: 16px;
    background-color: $white;
    border: 1px solid $gray-300;
    box-sizing: border-box;
    border-radius: 50%;

    &:focus {
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px $white,
        0px 0px 0px 4px $blue-500;
      border-radius: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  &--disabled {
    @extend .radio-container;
    :hover {
      cursor: not-allowed;
    }
  }
}

.radio-container input:checked ~ .radio-checkmark {
  background-color: $blue-600;
  border-color: $blue-600;
}

.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}

.radio-container .radio-checkmark:after {
  width: 6px;
  height: 6px;
  left: calc(50% - 6px / 2);
  top: calc(50% - 6px / 2);
  background-color: $white;
  border-radius: 50%;
}

.input-label-radio {
  @extend .base-regular;
  font-weight: 400;
}
