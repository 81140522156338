@import 'styles/colors';

.body-wrapper {
  padding: 24px 24px 16px 24px;
  background-color: $gray-50;
  height: calc(100% - 55px);
  overflow-y: auto;

  .summary-cards-wrapper {
    display: flex;
    gap: 25px;
    position: relative;
  }

  .see-all-surveys-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 24px;

    .see-all-surveys-title {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .btn-link {
      text-decoration: underline;
    }
  }

  .home-survey-tabs {
    .react-tabs__tab--selected {
      background-color: transparent;
    }

    .react-tabs__tab-panel {
      padding: 14px 0;
      min-height: 266px;

      .surveys-empty-state {
        .typography--variant-h3 {
          margin-bottom: 0;
          padding-bottom: 0;
        }

        .empty-state-description-default {
          margin-bottom: 0;
          margin-top: 8px;
        }

        .empty-state-action-default {
          margin-top: 24px;
        }
      }
    }
  }

  .calendar-container {
    margin-top: 26px;

    .calendar-header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title-view-type-wrapper {
        display: flex;
        align-items: center;
        gap: 40px;

        .calendar-title {
          display: flex;
          align-items: center;
          position: relative;
        }
      }

      .statuses-indicator-container {
        display: flex;
        align-items: center;
        gap: 30px;

        .status-indicator {
          display: flex;
          align-items: center;
          gap: 10px;

          .status-color {
            height: 18px;
            width: 18px;
            border-radius: 2px;

            &--active {
              @extend .status-color;
              background-color: $blue-400;
            }
            &--planned {
              @extend .status-color;
              background-color: $yellow-400;
            }
            &--finished {
              @extend .status-color;
              background-color: $green-400;
            }
          }
        }
      }
    }
  }

  .calendar-load-more-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    min-height: 41px;
  }
}
