@import '../../../styles/colors';

.popover-content {
  background-color: $white !important;
  color: $brand-midnight !important;
  max-width: 269px !important;
  height: 293px;
  // padding-left: 20px !important;
  // padding-right: 20px !important;
}
.__react_component_tooltip.show {
  opacity: 3.9 !important;
}

.popover-content.popper-top-start.place-top::after {
  border-top-color: $white !important;
  left: 10% !important;
}
.popover-content.popper-bottom-start.place-top::after {
  border-top-color: $white !important;
  border-bottom-color: $white !important;
  left: 10% !important;
}

.popover-content.popper-top-end.place-top::after {
  border-top-color: $white !important;
  left: 90% !important;
}
.popover-content.popper-bottom-end.place-top::after {
  border-top-color: $white !important;
  left: 90% !important;
}

.popover-content.popper-top-middle.place-top::after {
  border-top-color: $white !important;
  left: 50% !important;
}
.popover-content.popper-bottom-middle.place-top::after {
  border-bottom-color: $white !important;
  left: 50% !important;
}

.popover-content.popper-bottom-start.place-bottom::after {
  border-bottom-color: $white !important;
  left: 10% !important;
}
.popover-content.popper-top-start.place-bottom::after {
  border-bottom-color: $white !important;
  left: 10% !important;
}

.popover-content.popper-bottom-end.place-bottom::after {
  border-bottom-color: $white !important;
  left: 90% !important;
}
.popover-content.popper-top-end.place-bottom::after {
  border-bottom-color: $white !important;
  left: 90% !important;
}

.popover-content.popper-bottom-middle.place-bottom::after {
  border-bottom-color: $white !important;
  left: 50% !important;
}

.popover-content.popper-top-middle.place-bottom::after {
  border-top-color: $white !important;
  left: 50% !important;
}

.popover-content.popper-right-start.place-right::after {
  border-right-color: $white !important;
  top: 10% !important;
}
.popover-content.popper-left-start.place-right::after {
  border-right-color: $white !important;
  border-left-color: $white !important;
  top: 10% !important;
}

.popover-header {
  display: flex;
  height: 46px;
}

.popover-header-icon {
  padding-top: 12px;
}

.popover-footer {
  height: 46px;
}

.popover-header-close-icon {
  padding-left: 110px;
  padding-top: 16px;
}

.ri-information-line {
  color: $brand-midnight;
  font-size: 25px;
  // padding-left: 18px;
}
.popover-header-title {
  padding-left: 25px;
  color: $brand-midnight;
  // padding-top: 20px;
  font-weight: 600;
}
