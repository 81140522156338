@import 'styles/colors';
@import 'styles/text';

.empty-state-content-default {
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.empty-state-action-default {
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-state-content-small {
  text-align: center;
  align-items: center;
  width: 276px;
  height: 284px;
  padding-top: 20px;
  padding-left: 20px;
}

.empty-state-action-small {
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-state-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-content-default {
  width: 320px;
  height: 240px;
}

.image-content-small {
  width: 127.5px;
  height: 100px;
}

.empty-state-header-default {
  @extend .base-medium;
  color: $brand-midnight;
}

.empty-state-header-small {
  @extend .small-medium;
  color: $brand-midnight;
}

.empty-state-description-default {
  @extend .extra-small-regular;
  color: $gray-600;
}

.empty-state-description-small {
  @extend .extra-small-medium;
  color: $gray-600;
}
