$black: #000;
$white: #fff;
$beige: #e5b285;
$midnight: #4b5563;
$brand-midnight: #050037;

$icon-background: #09121f;

$pale-rust: #ef6950;
$rose: #c30052;
$violet-red: #881798;
$navy-blue: #0063b1;
$mint-dark: #018574;
$storm: #4c4a48;
$camouflage: #7e735f;
$metal-blue: #515c6b;

$surface-default: #e4e5e7;
$decorative-surface-one: #ffc96b;
$decorative-surface-two: #ffc4b0;
$decorative-surface-three: #92e6b5;
$decorative-surface-four: #91e0d6;
$decorative-surface-five: #fdc9d0;

$icon-subdued: #8c9196;
$decorative-icon-one: #7e5700;
$decorative-icon-two: #af294e;
$decorative-icon-three: #006d41;
$decorative-icon-four: #006a68;
$decorative-icon-five: #ae2b4c;

$decorative-text-one: #3d2800;
$decorative-text-two: #490b1c;
$decorative-text-three: #002f19;
$decorative-text-four: #002d2d;
$decorative-text-five: #4f0e1f;

$avatar-pale-rust: #ef6950;
$avatar-mint-dark: #018574;
$avatar-violet-red: #881798;
$avatar-navy-vlue: #0063b1;
$avatar-rose: #c30052;

$beige-900: #7c290f;
$beige-800: #9a4421;
$beige-700: #b8683e;
$beige-600: #d59569;
$beige-500: #e5b285;
$beige-400: #f5cea3;
$beige-300: #f7d8b5;
$beige-200: #f9e2c8;
$beige-100: #fbebda;
$beige-50: #fdf5ed;

$gray-900: #111827;
$gray-800: #1f2937;
$gray-700: #374151;
$gray-600: #4b5563;
$gray-500: #6b7280;
$gray-400: #9ca3af;
$gray-300: #d1d5db;
$gray-200: #e5e7eb;
$gray-100: #f3f4f6;
$gray-50: #f9fafb;

$green-900: #064e3b;
$green-800: #065f46;
$green-700: #047857;
$green-600: #059669;
$green-500: #10b981;
$green-400: #34d399;
$green-300: #6ee7b7;
$green-200: #a7f3d0;
$green-100: #d1fae5;
$green-50: #ecfdf5;

$yellow-900: #78350f;
$yellow-800: #92400e;
$yellow-700: #b45309;
$yellow-600: #d97706;
$yellow-500: #f59e0b;
$yellow-400: #fbbf24;
$yellow-300: #fcd34d;
$yellow-200: #fde68a;
$yellow-100: #fef3c7;
$yellow-50: #fffbeb;

$red-900: #7f1d1d;
$red-800: #991b1b;
$red-700: #b91c1c;
$red-600: #dc2626;
$red-500: #ef4444;
$red-400: #f87171;
$red-300: #fca5a5;
$red-200: #fecaca;
$red-100: #fee2e2;
$red-50: #fef2f2;

$blue-900: #1e3a8a;
$blue-800: #1e40af;
$blue-700: #1d4ed8;
$blue-600: #2563eb;
$blue-500: #3b82f6;
$blue-400: #60a5fa;
$blue-300: #93c5fd;
$blue-200: #bfdbfe;
$blue-100: #dbeafe;
$blue-50: #eff6ff;

//---------------------------- to be removed
$blue: rgb(80, 123, 247);
$blue-dark: rgb(23, 77, 240);
$blue-light: rgb(234, 239, 253);
$gray: rgb(149, 154, 162);
$gray-dark: rgb(57, 60, 61);
$gray-light: rgb(242, 245, 247);
$red: rgb(192, 31, 0);
$red-light: rgb(248, 236, 239);

// reporting
$kf-blue-hover: #cce1ff;

// Foundations
$disabled: #e5e7eb;
$focus: #3b82f6;
