@import 'styles/colors';

.header-alerts-info-content {
  display: flex;
}
.login-as-user-content {
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  background-color: $blue-50;
  height: 48px;
  width: 100%;
}

.notification-content {
  background-color: $gray-600;
  color: $white;
  width: 100%;
  padding: 16px 36px;
}

.header-alerts-info-items {
  display: flex;
}

.notification-item {
  align-items: flex-start;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .notification-text-content {
    .typography--variant-h5 {
      margin-top: 0;
      margin-bottom: 2px;
    }

    .typography--variant-h6 {
      margin: 0;
    }

    .notification-description-text > * {
      margin: 0;
    }
  }
}

.login-as-user-item {
  flex-direction: row;
  align-items: center;
}
