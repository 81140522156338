@import 'styles/colors';

.notification-box-content {
  display: flex;
}
.notification-header-text {
  color: $white;
}

.notification-description-text {
  color: $white;

  .btn-base {
    display: inline-block;
    padding: 0 5px;
  }
}

.see-more-button-label {
  color: $white;
  text-decoration: underline;
}

.notification-close-icon {
  cursor: pointer;
}

.custom-link {
  color: $gray-300;
  text-decoration: none;

  &.hovered-link {
    color: white;
    text-decoration: none;
  }

  &.visited-link {
    color: white;
    text-decoration: underline;
  }
}
