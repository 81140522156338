.card-container {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 40px;

  hr.divider-solid {
    margin-left: -20px;
    margin-right: -20px;
  }

  hr.divider-dashed {
    margin-left: -20px;
    margin-right: -20px;
  }

  .ad-element-text:nth-child(n + 3) {
    margin-top: 12px;
  }
}

.no-preview-reports {
  .btn-primary {
    font-size: 12px;

    i {
      font-size: 16px;
    }
  }
}
