@import '../../../styles/colors';
@import '../../../styles/text';

.breadcrumb {
  display: flex;
}

.breadcrumb-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: fit-content;
  height: 32px;
  box-sizing: border-box;
  padding: 4px 6px 4px 6px;
  color: $gray-600;
  cursor: pointer;

  &:hover {
    background-color: $gray-200;
    border-radius: 4px;
  }

  &:focus {
    background-color: $gray-300;
    border-radius: 4px;
  }

  i {
    font-size: 20px;
    font-weight: 600;
  }

  .icon {
    margin: 0 12px;
  }

  span {
    @extend .small-medium;
    font-size: 12px;
    font-weight: 400;
  }
}

.more {
  background-color: $gray-200;
  border-radius: 4px;
  padding: 4px 10px;
}

.breadcrumb-item-active {
  color: $blue-600;
}

.breadcrumb-more-wrapper {
  i {
    font-size: 22px;
    font-weight: 600;
    color: $gray-600;
  }
}

.simple-breadcrumb {
  list-style: none;
  display: flex;
  align-items: flex-end;
  padding: 0;
  margin-top: 0;
  margin-bottom: 14px;

  .simple-breadcrumb-item {
    color: $gray-600;
    font-size: 12px;
    font-weight: 400;

    .simple-breadcrumb-active {
      color: $blue-600;
    }

    span {
      i {
        position: relative;
        bottom: -4px;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 16px;
      }
    }
  }
}
