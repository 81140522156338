@import '../../../styles/colors';
@import '../../../styles/shadow';
@import '../../../styles/text';

.modal-small {
  @extend .shadow-lg;

  background: $white;
  position: absolute;
  left: 50%;
  right: 50%;
  z-index: 1101;
  width: 400px;
  border-radius: 2px;
  top: 50%;
  transform: translate(-50%, -50%);

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    width: 400px;
    height: 62px;
    padding: 12px 24px;
    background: $white;
    box-shadow: inset 0px -1px 0px $gray-200;

    .modal-close-icon {
      font-size: 22px;
      cursor: pointer;

      &:hover {
        font-weight: 900;
      }
    }
  }

  .modal-body {
    box-sizing: border-box;
    padding: 24px;
    width: 400px;
    background: $white;
  }

  .modal-footer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    width: 400px;
    height: 62px;
    background: $gray-50;
  }
}

.modal-medium {
  @extend .modal-small;

  width: 603px;
  position: fixed;
  z-index: 1000000000;

  .modal-header {
    width: 600px;
  }

  .modal-body {
    width: 100%;
    min-height: 100px;
    overflow-y: auto;
  }

  .modal-footer {
    width: 100%;
  }
}

.modal-large {
  @extend .modal-small;

  width: 800px;

  .modal-header {
    width: 800px;
  }

  .modal-body {
    width: 800px;
  }

  .modal-footer {
    width: 800px;
  }
}

.modal-full {
  @extend .modal-small;

  width: 100%;

  .modal-header {
    width: 100%;
    height: auto;

    h5 {
      margin: 0;
    }
  }

  .modal-body {
    width: 100%;
    height: calc(100vh - 52px);
    box-sizing: border-box;
    padding: 0;

    .modal-canvas-body {
      padding: 24px;
      background-color: $gray-50;
      height: calc(100% - 48px);
    }
  }

  .modal-footer {
    width: 100%;
  }
}
