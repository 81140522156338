@import 'styles/colors';
@import 'styles/text';

.header-content {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
  align-items: center;
  background-color: $beige-50;
  padding: 10px 24px;

  .logo {
    display: flex;
  }

  .menu {
    display: flex;
    align-items: center;
    margin-left: 37px;

    i {
      @extend .base-medium;

      color: $gray-800;
      font-size: 20px;
    }

    .menu-more {
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .profile-notification {
    display: flex;
    margin-left: auto;
    position: relative;

    .notification {
      display: flex;
      align-items: center;

      .notification-count {
        @extend .extra-small-medium;

        color: $gray-50;
        padding: 2px 6px;
        background-color: $red-500;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .orgunit-selection {
      .orgunit-selection-button {
        background: none;
        box-shadow: none;
        border: none;
        border-radius: 0;
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    margin-left: 15px;

    .avatar {
      width: 32px;
      border-radius: 50%;
    }

    .profile-menu {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .user-profile-avatar {
    cursor: pointer;
  }
}
