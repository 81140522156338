@import 'styles/colors';

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .typography--variant-h2 {
    span {
      color: $gray-500;
    }
  }

  .header-button {
    position: relative;
  }
}
