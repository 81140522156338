@import 'styles/colors';
@import 'styles/text';

.alert-message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 10px;
  border-radius: 4px;

  .alert-message-icon-container {
    height: 100%;
    display: flex;
  }

  .alert-message-icon-container i {
    font-size: 17px;
  }

  .alert-message-content-container {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  span {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .am-close-btn {
    cursor: pointer;
    i {
      font-size: 20px;
    }

    &:hover {
      filter: brightness(0.5);
    }
  }
}

.variant-error {
  background-color: $red-100;
  color: $red-700;
  border: 1px solid $red-300;
}

.variant-info {
  background-color: $blue-100;
  color: $blue-700;
  border: 1px solid $blue-300;
}

.variant-success {
  background-color: $green-100;
  color: $green-700;
  border: 1px solid $green-300;
}

.variant-warning {
  background-color: $yellow-100;
  color: $yellow-700;
  border: 1px solid $yellow-300;
}
