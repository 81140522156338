@import 'styles/colors';
@import 'styles/shadow';
@import 'styles/text';
@import 'styles/select';
@import 'styles/inputs';

#select-menu {
  width: 295px !important;
}

.dropdown-select-label {
  @extend .small-medium;

  display: block;
  margin-bottom: 4px 0;
  color: $gray-700;
}

.dropdown-select {
  @extend .select;

  width: 236px !important;
  height: 40px !important;
  cursor: pointer;

  span {
    @extend .base-regular;

    color: $gray-900;
  }
}

.dropdown-select-with-element {
  cursor: pointer;
  span {
    @extend .base-regular;

    color: $gray-500;
  }
  &.__disabled {
    pointer-events: none;
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.dropdown-select-drop-down {
  @extend .shadow-lg;

  position: absolute;
  z-index: 1001;
  width: 324px;
  max-height: 552px;
  padding: 4px 0;
  background-color: $white;
  border: 1px solid $gray-100;
  border-radius: 6px;

  .scroll {
    max-height: auto;
    overflow-y: scroll;
  }

  .back-to-filters {
    @extend .base-regular;

    display: flex;
    align-items: center;
    color: $blue-700;
    padding: 0px 10px;

    i {
      font-size: 16px;
    }

    span {
      cursor: pointer;
    }
  }

  .search {
    @extend .text-input;

    margin: 12px;
  }

  li {
    @extend .small-regular;

    color: $gray-900;
    list-style: none;
    margin: 12px;
    cursor: pointer;

    .checkbox-container {
      display: inline !important;
    }
  }

  li.selected {
    display: flex;
    justify-content: space-between;
    font-weight: 600;

    i {
      font-size: 20px;
      color: $blue-600;
    }
  }

  ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 10px;

    &:first-child {
      margin: 0 12px;
      max-height: 375px;
      overflow-y: unset;
      border-top: 1px solid $gray-200;
    }

    li {
      @extend .base-regular;

      .item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: $gray-900;
        margin: 24px 0;
        cursor: pointer;

        .arrow-icon {
          font-size: 24px;
          color: $gray-400;
        }

        span {
          color: $gray-500;
        }
      }
    }
  }

  .dropdown--footer {
    display: flex;
    justify-content: space-between;
    margin: 0 16px 20px 16px;
  }
}

.dropdown-select-drop-down-element {
  @extend .shadow-lg;

  position: absolute;
  z-index: 1001;
  width: 287px;
  max-height: 210px;
  padding: 4px 0;
  background-color: $white;
  border: 1px solid $gray-100;
  border-radius: 6px;
  margin-left: -275px;
  margin-top: -1px;

  .back-to-filters {
    @extend .base-regular;

    display: flex;
    align-items: center;
    color: $blue-700;
    padding: 8px 10px;

    i {
      font-size: 16px;
    }

    span {
      cursor: pointer;
    }
  }

  .search {
    @extend .text-input;

    margin: 12px;
  }

  li {
    @extend .base-regular;

    color: $gray-700;
    list-style: none;
    // margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;

    .checkbox-container {
      display: inline !important;
    }
  }

  li.selected {
    display: flex;
    justify-content: space-between;
    font-weight: 600;

    i {
      font-size: 20px;
      color: $blue-600;
    }
  }

  ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    // padding-inline-start: 10px;

    &:first-child {
      // margin: 0 12px;
      // max-height: 375px;
      // overflow-y: scroll;
      // border-top: 1px solid $gray-200;
    }

    li {
      @extend .base-regular;

      .item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: $gray-900;
        margin: 24px 0;
        cursor: pointer;

        .arrow-icon {
          font-size: 24px;
          color: $gray-400;
        }

        span {
          color: $gray-700;
        }
      }
    }
  }

  .dropdown--footer {
    display: flex;
    justify-content: space-between;
    margin: 0 16px 20px 16px;
  }
}

///
.dropdown-select-drop-down-resize {
  height: 100%;
  overflow-y: scroll;

  .scroll {
    max-height: auto;
    overflow-y: scroll;
  }

  .back-to-filters {
    @extend .base-regular;

    display: flex;
    align-items: center;
    color: $blue-700;
    padding: 0px 10px;

    i {
      font-size: 16px;
    }

    span {
      cursor: pointer;
    }
  }

  .search {
    @extend .text-input;
    width: calc(100% - 25px);
    margin: 12px;
  }

  li {
    @extend .small-regular;

    color: $gray-900;
    list-style: none;
    margin: 12px;
    cursor: pointer;

    .checkbox-container {
      display: inline !important;
    }
  }

  li.selected {
    display: flex;
    justify-content: space-between;
    font-weight: 600;

    i {
      font-size: 20px;
      color: $blue-600;
    }
  }

  ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    // padding-inline-start: 10px;

    &:first-child {
      margin: 0 12px;
      max-height: 375px;
      overflow-y: unset;
      border-top: 1px solid $gray-200;
    }

    li {
      @extend .base-regular;

      .item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: $gray-900;
        margin: 24px 0;
        cursor: pointer;

        .arrow-icon {
          font-size: 24px;
          color: $gray-400;
        }

        span {
          color: $gray-500;
        }
      }
    }
  }

  .dropdown--footer {
    display: flex;
    justify-content: space-between;
    margin: 0 16px 20px 16px;
  }

  .dropdown-hr {
    margin: 0 12px;
    border: 1px solid $gray-200;
  }
}

.userTable-dropdown {
  @extend .shadow-lg;

  position: absolute;
  z-index: 1001;
  width: 287px;
  max-height: 210px;
  padding: 4px 0;
  background-color: $white;
  border: 1px solid $gray-100;
  border-radius: 6px;
  top: 60px;
  right: 30px;

  .back-to-filters {
    @extend .base-regular;

    display: flex;
    align-items: center;
    color: $blue-700;
    padding: 8px 10px;
    border-bottom: 1px solid $gray-200;

    i {
      font-size: 16px;
    }

    span {
      cursor: pointer;
    }
  }

  .search {
    @extend .text-input;

    margin: 12px;
  }

  li {
    @extend .base-regular;

    color: $gray-700;
    list-style: none;
    // margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;

    .checkbox-container {
      display: inline !important;
    }
  }

  li.selected {
    display: flex;
    justify-content: space-between;
    font-weight: 600;

    i {
      font-size: 20px;
      color: $blue-600;
    }
  }

  ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    // padding-inline-start: 10px;

    &:first-child {
      // margin: 0 12px;
      // max-height: 375px;
      // overflow-y: scroll;
      // border-top: 1px solid $gray-200;
    }

    li {
      @extend .base-regular;

      .item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: $gray-900;
        margin: 24px 0;
        cursor: pointer;

        .arrow-icon {
          font-size: 24px;
          color: $gray-400;
        }

        span {
          color: $gray-700;
        }
      }
    }
  }

  .dropdown--footer {
    display: flex;
    justify-content: space-between;
    margin: 0 16px 20px 16px;
  }
}

/// Resize styles
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 0;
  right: 0;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjM2MzYgMjFMMjEgMTcuMzYzNk0yMSAxMS45MDkxTDExLjkwOTEgMjFNMjEgNi40NTQ1NUw2LjQ1NDU1IDIxTTIxIDFMMSAyMSIgc3Ryb2tlPSIjOUNBM0FGIi8+Cjwvc3ZnPgo=);
  // background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
  z-index: 1500;
}
/* demo */
// body {
//   background: white;
//   padding: 20px;
// }
#content {
  width: 100%;
  background: #eee;
  padding-bottom: 200px;
}
.layoutRoot {
  display: flex;
  flex-wrap: wrap;
}

.tab-panel-tree-dropdown {
  position: relative;
}

.box {
  display: inline-block;
  background-color: $white;
  border: 1px solid $gray-100;
  border-radius: 6px;
  // text-align: center;
  // padding: 10px;
  box-sizing: border-box;
  // margin-bottom: 10px;
  overflow: hidden;
  position: absolute;
  z-index: 1;

  @extend .shadow-lg;
  // margin: 20px;
}

.tab-panel-tree-dropdown > .box {
  top: 46px;
  left: 8px;
}

.box .text {
  // text-align: center;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // margin: auto;
  // height: 36px;
}
.box3 .react-resizable-handle {
  display: none;
}
.box3:hover .react-resizable-handle {
  display: block;
}

.expand-select-container > li {
  margin: 1px 0;
  padding: 0 3px;

  &:hover {
    background-color: $gray-100;
    border-radius: 4px;
  }

  p {
    font-weight: 400;
  }

  i {
    color: $blue-600;
    font-weight: 700;
    font-size: 18px;
  }
}

.expand-select-container {
  p.li-content-active {
    font-weight: 500;
  }
}

.dropdown-select-disabled {
  background-color: $gray-200;
  // border: none;
  cursor: not-allowed;
}

.dropdown-select-disabled span {
  color: $gray-400;
  font-size: 16px;
}
