@import 'styles/colors';

.tour-custom-tooltip {
  background-color: $blue-600;
  border-radius: 4px;
  overflow: hidden;
  padding: 16px;
  width: 400px;
  font-family: Inter;

  .tour-step-title {
    .tour-title {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .tour-step-content {
    margin-top: 8px;

    .tour-content {
      font-weight: 300;

      span {
        display: block;

        &:not(:first-of-type) {
          margin-top: 20px;
        }
      }
    }
  }

  .tour-tooltip-footer {
    margin-top: 16px;
    display: flex;
    gap: 10px;

    .tour-controls {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.25px;
      text-align: left;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 4px;
      transition: ease-in-out 0.2s;
    }

    .tour-stop-button {
      background-color: transparent;
      color: #ffffff;
      padding: 7px 0px 7px 0px;

      &:hover {
        background-color: #ffffff33;
      }
    }

    .tour-next-button {
      background-color: #ffffff;
      color: $brand-midnight;
      padding: 7px 11px 7px 11px;

      &:hover {
        background-color: #e7e7e7;
      }
    }
  }
}
