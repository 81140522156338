@import '../../../styles/colors';

.star-yellow {
  color: $yellow-400 !important;
}

.star-grey {
  color: $gray-500 !important;
}

.star-size-small {
  font-size: 15px;
}

.star-size-medium {
  font-size: 20px;
}

.star-size-large {
  font-size: 25px;
}

.star-content-div {
  // padding-top: 10px;
}
