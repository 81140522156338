@import '../../../styles/colors';
@import '../../../styles/text';

.toggle-switch-container {
  display: flex;
  align-items: center;

  .on-off {
    @extend .small-medium;

    color: $gray-900;
    margin: 0px 8px;
  }

  .default-toggle-switch {
    position: relative;
    width: 40px;
    height: 24px;
    display: inline-block;
  }

  .large-toggle-switch {
    @extend .default-toggle-switch;

    width: 73.33px;
    height: 44px;
  }

  .small-toggle-switch {
    @extend .default-toggle-switch;

    width: 26.67px;
    height: 16px;
  }

  .checkbox {
    display: none;
  }

  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;

    .default-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;

      &::before,
      &::after {
        float: left;
        width: 50%;
        height: 24px;
      }

      &::before {
        content: ' ';
        background-color: $blue-600;
      }

      &::after {
        content: ' ';
        background-color: $gray-400;
      }
    }

    .large-inner {
      @extend .default-inner;
      &::before,
      &::after {
        height: 44px;
      }
    }

    .small-inner {
      @extend .default-inner;
      &::before,
      &::after {
        height: 16px;
      }
    }

    .default-switch {
      display: block;
      width: 20px;
      background: $white;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: 2px;

      border-radius: 50%;
      transition: all 0.3s ease-in 0s;
    }

    .large-switch {
      @extend .default-switch;
      width: 36.67px !important;
    }

    .small-switch {
      @extend .default-switch;
      width: 13.33px !important;
    }

    &:hover {
      .default-inner::before {
        background-color: $blue-700;
      }

      .default-inner:after {
        background-color: $gray-300;
      }

      .default-switch {
        background: $gray-200;
      }
    }

    &:focus {
      .default-inner::before {
        background-color: $blue-700;
      }

      .default-inner:after {
        background-color: $gray-300;
      }

      .default-switch {
        background: $gray-50;
      }
    }
  }

  .checkbox:checked + .label .default-inner {
    margin-left: 0;
  }

  .checkbox:checked + .label .default-switch {
    right: 0px;
  }
}

.switch-disabled {
  .default-inner,
  .large-inner,
  .small-inner {
    &::before {
      background-color: $blue-200 !important;
    }

    &::after {
      background-color: $gray-200 !important;
    }
  }

  .default-switch,
  .large-switch,
  .small-switch {
    background: $gray-50 !important;
  }
}

.toggle-view-only .default-inner::before,
.toggle-view-only .small-inner::before,
.toggle-view-only .large-inner::before {
  opacity: 0.6;
}

.toggle-view-only {
  pointer-events: none;
}
