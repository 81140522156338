@import 'styles/colors';
@import 'styles/shadow';
@import 'styles/text';

.textarea {
  @extend .base-regular;
  @extend .shadow-sm;

  width: 547px;
  height: 105px;
  background: $white;
  padding: 9px 13px;
  color: $gray-900;
  border: 1px solid $gray-300;
  border-radius: 6px;
  ba ::placeholder {
    color: $gray-500;
  }

  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjM2MzYgMjFMMjEgMTcuMzYzNk0yMSAxMS45MDkxTDExLjkwOTEgMjFNMjEgNi40NTQ1NUw2LjQ1NDU1IDIxTTIxIDFMMSAyMSIgc3Ryb2tlPSIjOUNBM0FGIi8+Cjwvc3ZnPgo=);
  background-size: 12px 12px;
  background-position: bottom right;
  background-repeat: no-repeat;

  &:hover {
    ::placeholder {
      color: $gray-600;
    }
  }

  &:focus {
    @extend .shadow-sm;
    outline: $blue-500 solid 2px;
  }
}

::-webkit-resizer {
  display: none;
}
