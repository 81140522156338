@import '../../../styles/colors';
@import '../../../styles/text';

.react-tabs__tab-list {
  border-bottom: 2px solid $gray-200;
}

.react-tabs__tab-panel {
  padding: 14px 24px;
  position: relative;
  min-height: 100px;
  overflow: auto;
}

.react-tabs__tab {
  @extend .base-medium;
  color: $gray-900;
  padding: 0 17px 8px 24px;
  font-size: 14px;

  &:focus {
    box-shadow: unset;
    border: unset;
    border-bottom: 2px solid $blue-700 !important;

    &::after {
      background: unset;
    }
  }

  &:focus-visible {
    outline: unset;
    border: unset;
    border-bottom: 2px solid $blue-700;
  }
}

.react-tabs__tab--selected {
  color: $blue-700;
  border: unset;
  border-bottom: 2px solid $blue-700 !important;
}
