@import 'styles/colors';
@import 'styles/text';

.error-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;

  position: static;
  width: 100%;
  height: 56px;
  left: 0px;
  top: 60px;

  /* Red/Red-50 */

  background: $red-50;
  /* Red/Red-300 */

  border: 1px solid $red-300;
  box-sizing: border-box;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0px;
}

.warning-text {
  @extend .base-regular;
  color: $red-700;
  padding-bottom: 4px;
  padding-left: 8px;
}
