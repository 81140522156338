@import '../../../styles/colors';
@import '../../../styles/shadow';
@import '../../../styles/text';
@import '../../../styles/inputs';

.text-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  .text-input-field {
    @extend .text-input;
  }

  .text-input-with-icon {
    width: 100% !important;
    padding: 8px 40px;
  }

  .text-input-icon {
    position: absolute;
    color: $gray-400;
    padding: 15px 15px;
    font-size: 20px;
  }

  .text-input-clear-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 10.61px;
    height: 10.61px;
    color: $gray-400;
    cursor: pointer;
  }

  .input-no-background {
    box-shadow: none;
    background-color: transparent;
    border: none;
  }
}
