@import 'styles/colors';
@import 'styles/shadow';

.side-panel-right {
  @extend .shadow-lg;

  position: fixed;
  right: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1101;
  width: 621px;
  height: 100%;
  background: $white;
  border-radius: 2px;
  transition: width 2s;

  .side-panel-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    width: 621px;
    height: 62px;
    padding: 12px 24px;
    background: $white;
    box-shadow: inset 0px -1px 0px $gray-200;

    .side-panel-close-icon {
      font-size: 22px;
      cursor: pointer;

      &:hover {
        font-weight: 900;
      }
    }
  }
  .side-panel-body {
    box-sizing: border-box;
    padding: 24px;
    width: 621px;
    // min-height: 800px;
    background: $white;
  }

  .side-panel-footer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    height: 62px;
    bottom: 0;
    position: absolute;
    background: $gray-50;
  }
}
