@import 'styles/colors';

.expand-select-container {
  margin: 12px;
  color: #111827;
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 8px 12px;

  .expand-select-selected-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: $brand-midnight;

    i.ri-code-line {
      transform: rotate(90deg);
      display: block;
    }
  }
}
