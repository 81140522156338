.carousel {
  overflow: hidden;
  width: calc(100% - 33.33%);

  .inner {
    white-space: nowrap;
    transition: transform 1s;
  }
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:nth-child(odd) {
    padding-right: 12px;
  }

  &:nth-child(even) {
    padding-left: 12px;
  }

  &:nth-child(4) {
    padding-right: 1px;
  }
}
