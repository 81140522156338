@import '../../../styles/colors';
@import '../../../styles/shadow';
@import '../../../styles/text';

.table {
  border: 1px solid $gray-200;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  background: $white;
  border-spacing: 0;
  width: 100%;

  thead {
    background: $gray-50;

    tr {
      th {
        @extend .extra-small-medium;

        padding: 12px 24px;
        color: $gray-500;
        border-bottom: 1px solid $gray-200;
        text-transform: uppercase;
        text-align: start;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 16px 24px;
        border-bottom: 1px solid $gray-200;
      }
    }
  }
}
