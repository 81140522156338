.respondent-landing-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .param-otp-checking {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 608px;
  }

  .mb {
    margin-bottom: 18px;
  }

  .button {
    width: inherit;
  }

  .otp-input-container {
    margin: 18px 0;
  }
}

@media only screen and (max-width: 768px) {
  .header-container {
    width: 95% !important;
  }

  body {
    min-width: unset !important;
  }
}
