@import 'styles/colors';

.survey-statuses-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 24px;

  .survey-card-container {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid $gray-200;
    background: #ffffff;
    width: 33.33%;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    .survey-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }

    .survey-progress {
      display: flex;
      align-items: center;
      gap: 15px;
      background-color: $gray-100;
      border-radius: 4px;
      padding: 8px;
      margin-top: 12px;
      margin-bottom: 20px;

      .progress-bar-container {
        flex-grow: 1;
        background-color: #ffffff;
        border-radius: 4px;

        .progress-bar-small {
          border-radius: 4px;
        }
      }
    }

    &:hover {
      background-color: #f8fbfe;
      border: 1px solid $gray-300;
    }
  }

  .carousel {
    .inner {
      .survey-card-container {
        width: 100%;
      }
    }
  }

  .survey-card-container.view-all-surveys {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;

    .symbols-content-div {
      display: flex;

      i {
        font-size: 28px;
        color: $blue-700;
      }
    }
  }
}
