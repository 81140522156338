@import 'styles/colors';

.symbols-green {
  color: $green-500 !important;
}

.symbols-gray {
  color: $gray-300 !important;
}

.symbols-red {
  color: $red-500 !important;
}

.symbols-black {
  color: $gray-900 !important;
}

.symbols-blue {
  color: $blue-700 !important;
}

.symbols-white {
  color: $white !important;
}

.symbols-size-small {
  font-size: 15px;
}
.symbols-size-medium {
  font-size: 20px;
}
