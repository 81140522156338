@import '../../../styles/colors';
@import '../../../styles/shadow';
@import '../../../styles/text';

.button-group-content {
  display: flex;
  border-radius: 6px 0px 0px 6px;
  align-items: center;

  div:first-child {
    border-radius: 6px 0px 0px 6px;
  }
  div:last-child {
    border-radius: 0px 6px 6px 0px;
    border-right: 1px solid $gray-300;
  }
}

.button-group-item {
  @extend .small-medium;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;

  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), inset 0px -1px 0px #d1d5db,
    inset 1px 0px 0px #d1d5db, inset 0px 1px 0px #d1d5db;

  flex: none;
  order: 0;
  flex-grow: 0;

  :hover {
    color: $blue-600;
  }

  &--selected {
    @extend .button-group-item;
    color: $blue-600;
  }
}
