@import './fonts';
@import './colors';
@import './shadow';
@import './text';
@import './inputs';
@import './select';

body {
  margin: 0;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  min-width: 1280px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Gimbal Grotesque;
  font-weight: medium;
}

h1 {
  font-size: 36px;
  line-height: 40px;
}

h2 {
  font-size: 30px;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  line-height: 28px;
}

h4 {
  font-size: 20px;
  line-height: 28px;
}

h5 {
  font-size: 18px;
  line-height: 28px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: $white;
}

::-webkit-scrollbar-thumb {
  background: $gray-300;
  border-radius: 9px;
}

::-webkit-scrollbar-thumb:hover {
  background: $gray-400;
}

::-webkit-scrollbar-thumb:active {
  background: $gray-500;
}
