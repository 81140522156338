@import 'styles/colors';

.summary-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  padding: 16px 24px;
  border: 1px solid $gray-200;
  border-radius: 4px;
  width: 100%;

  .card-summary {
    .typography--variant-h5 {
      line-height: normal;
    }

    .card-summery-contents {
      display: flex;
      gap: 5px;

      .typography--variant-h2 {
        line-height: 1;
      }

      button {
        color: inherit;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        font-size: 16px;
        position: relative;
        top: 3px;
      }
    }
  }

  .card-icon {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    i {
      font-size: 20px;
    }
  }

  &--card-blue {
    @extend .summary-card-container;
    background-color: $blue-100;
    height: 100%;

    .card-summary {
      .typography--variant-h5 {
        color: $blue-600;
      }

      .card-summery-contents {
        .typography--variant-h2 {
          color: $blue-600;
        }
        color: $blue-600;
      }
    }

    .card-icon {
      background-color: $blue-50;

      i {
        color: $blue-500;
      }
    }
  }

  &--card-green {
    @extend .summary-card-container;
    background-color: $green-100;
    height: 100%;

    .card-summary {
      .typography--variant-h5 {
        color: $green-600;
      }

      .card-summery-contents {
        .typography--variant-h2 {
          color: $green-600;
        }
        color: $green-600;
      }
    }

    .card-icon {
      background-color: $green-50;

      i {
        color: $green-600;
      }
    }
  }

  &--card-yellow {
    @extend .summary-card-container;
    background-color: $yellow-100;
    height: 100%;

    .card-summary {
      .typography--variant-h5 {
        color: $yellow-600;
      }

      .card-summery-contents {
        .typography--variant-h2 {
          color: $yellow-600;
        }
        color: $yellow-600;
      }
    }

    .card-icon {
      background-color: $yellow-50;

      i {
        color: $yellow-400;
      }
    }
  }

  &--card-beige {
    @extend .summary-card-container;
    background-color: $beige-200;
    height: 100%;

    .card-summary {
      .typography--variant-h5 {
        color: $beige-900;
      }

      .card-summery-contents {
        .typography--variant-h2 {
          color: $beige-900;
        }
        color: $beige-900;
      }
    }

    .card-icon {
      background-color: $beige-50;

      i {
        color: $beige-600;
      }
    }
  }
}

.guided-tour-1-summary {
  width: 0px;
  display: block;
  position: absolute;
  left: calc(25% / 2);
  bottom: 0px;
}

.summery-popover-content {
  min-width: 300px;
  max-width: 415px;
  padding: 24px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px -4px 15px -3px rgba(0, 0, 0, 0.08),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  position: relative;

  .popover-header {
    display: block;
    height: auto;
    margin-bottom: 24px;
  }

  .popover-body {
    min-height: 70px;
    max-height: 150px;
    overflow: auto;

    .summery-popover-item {
      display: flex;
      align-items: baseline;
      gap: 5px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      i {
        font-size: 10px;
        color: $blue-600;
        position: relative;
        top: -1px;
      }

      .summery-item-details {
        span {
          color: $gray-500;
        }
      }
    }
  }
  .summary-content-spinner {
    position: absolute;
    padding: 12px;
    width: calc(100% - 48px);
    background-color: rgb(255 255 255 / 70%);
    bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.popover-close-button {
  border: 0px;
  padding: 0px;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

.summery-more-info-popover {
  width: 25%;

  .card-summary {
    text-align: left;
  }
}
.summery-card-info-icon {
  margin-top: 4px;
  font-size: 16px;
}
