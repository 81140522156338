@import '../../../styles/colors';
@import '../../../styles/text';

/* The container */
.checkbox-container {
  display: flex;

  position: relative;
  padding-left: 27px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 4px;
  width: 16px;
  height: 16px;
  background-color: $white;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: $blue-600;
  border-color: $blue-600;
}

/* Create the checkbox-checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkbox-checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

.checkbox-container input:indeterminate ~ .checkbox-checkmark:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border: solid white;

  border-width: 2px 0 0 0;
  position: absolute;
  top: 6px;
  left: 2px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.checkbox-container input:indeterminate ~ .checkbox-checkmark {
  background-color: $blue-600;
  border-color: $blue-600;
}

/* Style the checkbox-checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
  left: 5px;
  top: 1px;
  width: 2px;
  height: 9px;
  border: solid $white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-sizing: content-box;
}

.checkbox-label {
  flex-direction: column;
  color: $brand-midnight !important;

  .description {
    margin: 2px 0 8px;
    color: $gray-500;
    font-weight: 400;
    font-size: 14px;
  }
}
