#guided-tour-modal {
  .modal-large {
    .modal-header {
      justify-content: center;
      width: 100%;
      height: 52px;
    }

    .modal-body {
      text-align: center;
      padding: 48px 24px 24px;

      img {
        height: 245px;
      }
    }

    .modal-footer {
      justify-content: center;
      gap: 12px;
    }
  }
}
